<template>
  <div :class="attrs.class">
    <div :class="wrapperClasses">
      <div v-if="hasLeftSlot" class="z-10 col-start-1 row-start-1 self-stretch w-12 items-center justify-center flex justify-self-start">
        <slot name="left" />
      </div>
      <input
        :id
        v-model="model"
        :label
        :name
        :data-left-slot="hasLeftSlot"
        :data-right-slot="hasRightSlot"
        :aria-invalid="!!errorMessage"
        :class="inputClasses"
        :type
        :placeholder
        v-bind="attrsWithoutClass"
      >
      <label
        :class="{ 'sr-only': hideLabel }"
        :for="id"
        class="max-w-full truncate pointer-events-none absolute py-0.5 text-muted dark:text-gray-400 transition-[transform,top] duration-100 transform -translate-y-3 scale-75 top-3 z-10 origin-top-left peer-focus:text-red-800 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-3 left-3 peer-data-[left-slot=true]:left-12 peer-autofill:opacity-100 peer-disabled:text-muted/50 peer-disabled:italic"
      >
        {{ label }}
      </label>
      <div v-if="hasRightSlot" class="z-10 col-start-1 row-start-1 self-stretch w-12 items-center justify-center flex justify-self-end">
        <slot name="right" />
      </div>
    </div>
    <Transition name="fade">
      <div v-if="errorMessage" class="text-left font-medium text-red text-xs px-3 py-1">
        {{ errorMessage }}
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

defineOptions({
  inheritAttrs: false
})
const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'text'
  },
  id: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ' '
  },
  hideLabel: {
    type: Boolean,
    required: false,
    default: false
  },
  wrapperClass: {
    type: [String, Array<string>],
    required: false,
    default: ''
  },
  inputClass: {
    type: [String, Array<string>],
    required: false,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  validator: {
    type: Object,
    required: false,
    default: () => ({})
  }
})

const model = defineModel({ type: String })

const attrs = useAttrs()
const attrsWithoutClass = ref({ ...attrs, class: undefined })

const slots = useSlots()

const hasLeftSlot = computed(() => !!slots.left)
const hasRightSlot = computed(() => !!slots.right)

const hasValidator = computed(() => !!Object.keys(props.validator).length)
const errorMessage = computed(() => hasValidator.value && props.validator.$errors?.length ? props.validator.$errors[0].$message : '')

const wrapperClasses = computed(() => twMerge('group grid *:col-start-1 *:row-start-1 bg-surface-raised w-full h-12 overflow-hidden border rounded bg-surface-raised relative has-[input:focus]:border-grey-700 has-[[aria-invalid=true]]:border-red transition-colors duration-100', props.wrapperClass))
const inputClasses = computed(() => twMerge('peer size-full px-3 bg-transparent pt-3 focus:outline-none placeholder:opacity-0 focus:placeholder:opacity-100 data-[left-slot=true]:pl-12 data-[right-slot=true]:pr-12 disabled:bg-grey-50 disabled:text-muted', props.hideLabel ? 'pt-0 placeholder-grey-300' : '', props.inputClass))

const id = computed((): string => props.id || `input-${props.name}`)
</script>
